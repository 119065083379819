<template>
	<div class="notice_popup">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit">관리총괄 승인하기</div>
		<div class="member"><span class="name">{{forwardData.mberNm}} ({{forwardData.loginId}}) 님을</span><br/>{{forwardData.corpNm}}의 관리총괄로 승인하시겠습니까?</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="$emit('close', true)">예</div>
		</div>
	</div>
</template>

<script>
export default {
	props:['param'],
	data() {
		return {
			forwardData : this.param
		}
	}
}
</script>